<template>
  <!-- Button trigger modal -->
  <button
    type="button"
    class="btn btn-primary d-none"
    data-bs-toggle="modal"
    data-bs-target="#modificationProposalModal"
    id="modificationProposalBtn"
  ></button>
  <!-- End Button trigger modal -->

  <!-- Modal -->
  <div
    id="modificationProposalModal"
    class="modal fade"
    data-bs-backdrop="static"
    data-bs-keyboard="false"
    tabindex="-1"
    role="dialog"
    aria-labelledby="modificationProposalModalTitle"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-dialog-centered modal-xl" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="modificationProposalModalTitle">
            Send modifications
          </h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
            id="closeRejectProposalModal"
          ></button>
        </div>
        <div class="modal-body">
          <!-- instructions -->
          <p>
            Please put your modification about this research in a reduced
            form,not greater than 1500 character and not lower than 50 character
            and we will notify the researcher about those modifications.
          </p>
          <!-- end instructions -->
          <!-- QuillEditor block -->
          <QuillEditor
            theme="snow"
            contentType="html"
            v-model:content="editorContent"
            :toolbar="[
              'bold',
              'italic',
              'underline',
              'strike',
              { size: ['small', false, 'large', 'huge'] },
              { header: [1, 2, 3, false] },
              { color: [] },
              { background: [] },
              { font: [] },
              { list: 'ordered' },
              { list: 'bullet' },
              { align: [] },
              'clean',
            ]"
          />
          <!-- end QuillEditor -->
        </div>
        <div class="modal-footer">
          <p
            class="fw-bold d-block w-100 mb-2"
            :class="messageClass"
            v-if="message.length > 0"
          >
            {{ message }}
          </p>
          <button
            v-if="!in_submission"
            type="button"
            class="btn btn-primary"
            @click="verifyContentLength"
          >
            Send modifications
          </button>
          <div
            class="spinner-border m-5 text-primary"
            role="status"
            v-if="in_submission"
          >
            <span class="visually-hidden">Loading...</span>
          </div>
          <button
            type="button"
            class="btn btn-white"
            data-bs-dismiss="modal"
            id="closeModificationBtn"
          >
            Close
          </button>
        </div>
      </div>
    </div>
  </div>
  <!-- End Modal -->
</template>

<script>
import { QuillEditor } from "@vueup/vue-quill";
import "@vueup/vue-quill/dist/vue-quill.snow.css";

import axiosConfig from "@/includes/axiosConfig.js";

export default {
  name: "modificationProposalModal",
  components: {
    QuillEditor,
  },
  props: {
    research: {
      type: Object,
      required: true,
    },
    getResearchInfo: {
      type: Function,
      required: true,
    },
  },
  computed: {
    researchInfo() {
      return this.research;
    },
  },
  data() {
    return {
      in_submission: false,
      message: "",
      messageClass: "",
      editorContent: "",
    };
  },
  methods: {
    verifyContentLength() {
      if (this.editorContent.length < 50 || this.editorContent.length > 6000) {
        this.message = "Please read the instruction above.";
        this.messageClass = "text-danger";
      } else {
        this.message = "";
        this.sendModification(this.researchInfo, this.editorContent);
      }
    },
    async sendModification(research, modificationBody) {
      this.in_submission = true;
      let val = {
        modificationBody: modificationBody,
      };
      await axiosConfig
        .post(`api/proposalWithModification/${research.id}`, val)
        .then((res) => {
          this.message =
            "the modification has been sent to the researcher successfully";
          this.messageClass = "text-success";
          this.getResearchInfo();
          setTimeout(() => {
            document.getElementById("closeModificationBtn").click();
          }, 4000);
          console.log(res);
        })
        .catch((err) => {
          console.log(err.response);
          this.message = err.response.data.message;
          this.messageClass = "text-danger";
          return;
        });
      this.in_submission = false;
    },
  },
};
</script>
