<template>
  <!-- Button trigger modal -->
  <button
    type="button"
    class="btn btn-primary d-none"
    data-bs-toggle="modal"
    data-bs-target="#reportInfoModal"
    id="reportInfoModalBtn"
  ></button>

  <!-- Modal -->
  <div
    class="modal fade"
    id="reportInfoModal"
    data-bs-backdrop="static"
    data-bs-keyboard="false"
    tabindex="-1"
    aria-labelledby="reportInfoModalLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-dialog-scrollable modal-lg">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="reportInfoModalLabel">Report details</h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body">
          <report-card :report="report" />
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-primary" data-bs-dismiss="modal">
            Close
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import reportCard from "@/components/reports/reportCard.vue";
export default {
  components: {
    reportCard,
  },
  props: {
    report: {
      required: true,
      type: Object,
    },
  },
  name: "reportInfoModal",
};
</script>

<style></style>
