<template>
  <!-- Button trigger modal -->
  <button
    type="button"
    class="d-none"
    data-bs-toggle="modal"
    id="certificateModalButton"
    data-bs-target="#closeCertificateModal"
  ></button>

  <!-- Modal -->
  <div
    class="modal fade"
    id="closeCertificateModal"
    tabindex="-1"
    aria-labelledby="closeResearchModalLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-lg">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="closeResearchModalLabel">
            Ethical Clearance Certificate
          </h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
            id="closecloseResearchModalBTN"
          ></button>
        </div>
        <div class="modal-body">
          <slot name="body" />
        </div>
        <div class="modal-footer">
          <div class="d-flex flex-row justify-content-between w-100">
            <div>
              <button
                type="button"
                class="btn btn-white mx-1"
                data-bs-dismiss="modal"
              >
                close
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
