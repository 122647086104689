<template>
  <div>
    <!-- filter section -->
    <div
      class="row gx-2 gx-md-3 m-4 justify-content-start"
      v-if="hasRole(['admin'])"
    >
      <div class="col-sm-6 col-md-4 mb-2 mb-md-0">
        <div class="mb-3">
          <label for="actionsFilter" class="form-label fw-bold fs-6"
            >Actions by filter</label
          >

          <div class="input-group input-group-merge">
            <div class="input-group-prepend input-group-text">
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M17.5 11H6.5C4 11 2 9 2 6.5C2 4 4 2 6.5 2H17.5C20 2 22 4 22 6.5C22 9 20 11 17.5 11ZM15 6.5C15 7.9 16.1 9 17.5 9C18.9 9 20 7.9 20 6.5C20 5.1 18.9 4 17.5 4C16.1 4 15 5.1 15 6.5Z"
                  fill="#2670b6"
                ></path>
                <path
                  opacity="0.3"
                  d="M17.5 22H6.5C4 22 2 20 2 17.5C2 15 4 13 6.5 13H17.5C20 13 22 15 22 17.5C22 20 20 22 17.5 22ZM4 17.5C4 18.9 5.1 20 6.5 20C7.9 20 9 18.9 9 17.5C9 16.1 7.9 15 6.5 15C5.1 15 4 16.1 4 17.5Z"
                  fill="#7abeef"
                ></path>
              </svg>
            </div>
            <!-- Select for filter roles -->
            <select
              class="form-select"
              id="selectRole"
              aria-label="Select location"
              @change="filterActions({ role: $event.currentTarget.value })"
              :disabled="in_submission"
            >
              <option selected value="%">All actions</option>
              <option value="REC-admin">actions by ethics committee</option>
              <option value="RSC-admin">actions by scientific committee</option>
              <option value="admin">actions by admin</option>
            </select>
            <!-- End Select -->
          </div>
        </div>
        <!-- End Form -->
      </div>
      <!-- End Col -->
    </div>
    <!-- end filter section -->

    <!-- loading icon -->
    <div
      class="d-flex justify-content-center text-primary"
      v-if="in_submission"
    >
      <div class="spinner-border" role="status">
        <span class="visually-hidden">Loading...</span>
      </div>
    </div>
    <!-- end loading icon -->

    <!-- actions content -->
    <div class="container m-4" v-if="actions.length > 0 && !in_submission">
      <div class="row justify-content-start">
        <div class="col-8">
          <!-- Step -->
          <ul class="step">
            <!-- loop the actions in steps -->
            <template v-for="(action, index) in actions" :key="action.id">
              <!-- Step Item (date)-->
              <li
                class="step-item"
                v-if="checkSameDate(action, actions[index - 1])"
              >
                <div class="step-content-wrapper">
                  <small class="step-divider fw-bold text-muted">{{
                    formatDate(action.created_at)
                  }}</small>
                </div>
              </li>
              <!-- End Step Item -->

              <!-- Step Item (regular)-->
              <li class="step-item">
                <div class="step-content-wrapper">
                  <!-- if the madeBy action have an profile image -->
                  <div
                    class="step-avatar"
                    v-if="action.made_by.profile_img_path != null"
                  >
                    <img
                      class="step-avatar-img"
                      :src="action.made_by.profile_img_path"
                      alt="Image Description"
                    />
                  </div>
                  <!-- if don't -->
                  <span v-else class="step-icon step-icon-soft-dark">{{
                    action.made_by.full_name[0]
                  }}</span>
                  <!-- step content -->
                  <div class="step-content">
                    <!-- the action made by name and role name and creation date -->
                    <h5 class="mb-1">
                      <a class="text-dark" href="#">{{
                        action.made_by.full_name
                      }}</a>
                      <div
                        class="d-flex flex-row fs-8 text-muted justify-content-between"
                      >
                        <!-- made by role action -->
                        <small>
                          <svg
                            class="me-1"
                            width="16"
                            height="16"
                            viewBox="0 0 24 24"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M20 14H18V10H20C20.6 10 21 10.4 21 11V13C21 13.6 20.6 14 20 14ZM21 19V17C21 16.4 20.6 16 20 16H18V20H20C20.6 20 21 19.6 21 19ZM21 7V5C21 4.4 20.6 4 20 4H18V8H20C20.6 8 21 7.6 21 7Z"
                              fill="#3182ce"
                            ></path>
                            <path
                              opacity="0.3"
                              d="M17 22H3C2.4 22 2 21.6 2 21V3C2 2.4 2.4 2 3 2H17C17.6 2 18 2.4 18 3V21C18 21.6 17.6 22 17 22ZM10 7C8.9 7 8 7.9 8 9C8 10.1 8.9 11 10 11C11.1 11 12 10.1 12 9C12 7.9 11.1 7 10 7ZM13.3 16C14 16 14.5 15.3 14.3 14.7C13.7 13.2 12 12 10.1 12C8.10001 12 6.49999 13.1 5.89999 14.7C5.59999 15.3 6.19999 16 7.39999 16H13.3Z"
                              fill="#2670b6"
                            ></path>
                          </svg>
                          <span v-if="action.made_by_role == 'REC-admin'">
                            {{ "Ethical committee admin" }}
                          </span>
                          <span v-else-if="action.made_by_role == 'REC-member'">
                            {{ "Ethical committee member" }}
                          </span>
                          <span v-else-if="action.made_by_role == 'RSC-admin'">
                            {{ "Scientific committee admin" }}
                          </span>
                          <span v-else-if="action.made_by_role == 'RSC-member'">
                            {{ "Scientific committee member" }}
                          </span>
                          <span v-else-if="action.made_by_role == 'admin'">
                            {{ "Super admin" }}
                          </span>
                          <span v-else>
                            {{ "Researcher" }}
                          </span>
                        </small>
                        <!-- created at action -->
                        <small class="fs-8"
                          ><svg
                            width="18"
                            height="18"
                            viewBox="0 0 24 24"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              opacity="0.3"
                              d="M20.9 12.9C20.3 12.9 19.9 12.5 19.9 11.9C19.9 11.3 20.3 10.9 20.9 10.9H21.8C21.3 6.2 17.6 2.4 12.9 2V2.9C12.9 3.5 12.5 3.9 11.9 3.9C11.3 3.9 10.9 3.5 10.9 2.9V2C6.19999 2.5 2.4 6.2 2 10.9H2.89999C3.49999 10.9 3.89999 11.3 3.89999 11.9C3.89999 12.5 3.49999 12.9 2.89999 12.9H2C2.5 17.6 6.19999 21.4 10.9 21.8V20.9C10.9 20.3 11.3 19.9 11.9 19.9C12.5 19.9 12.9 20.3 12.9 20.9V21.8C17.6 21.3 21.4 17.6 21.8 12.9H20.9Z"
                              fill="#3182ce"
                            ></path>
                            <path
                              d="M16.9 10.9H13.6C13.4 10.6 13.2 10.4 12.9 10.2V5.90002C12.9 5.30002 12.5 4.90002 11.9 4.90002C11.3 4.90002 10.9 5.30002 10.9 5.90002V10.2C10.6 10.4 10.4 10.6 10.2 10.9H9.89999C9.29999 10.9 8.89999 11.3 8.89999 11.9C8.89999 12.5 9.29999 12.9 9.89999 12.9H10.2C10.4 13.2 10.6 13.4 10.9 13.6V13.9C10.9 14.5 11.3 14.9 11.9 14.9C12.5 14.9 12.9 14.5 12.9 13.9V13.6C13.2 13.4 13.4 13.2 13.6 12.9H16.9C17.5 12.9 17.9 12.5 17.9 11.9C17.9 11.3 17.5 10.9 16.9 10.9Z"
                              fill="#2670b6"
                            ></path>
                          </svg>
                          {{ action.created_at }}</small
                        >
                      </div>
                    </h5>

                    <!-- action description -->
                    <p class="font-size-sm">
                      {{ action.action_desc }}
                      <!-- action icon -->
                      <span class="px-1">
                        <!-- if action type report -->
                        <svg
                          v-if="action.report != null"
                          width="18"
                          height="18"
                          viewBox="0 0 24 24"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            opacity="0.3"
                            fill-rule="evenodd"
                            clip-rule="evenodd"
                            d="M4.85714 1H11.7364C12.0911 1 12.4343 1.12568 12.7051 1.35474L17.4687 5.38394C17.8057 5.66895 18 6.08788 18 6.5292V19.0833C18 20.8739 17.9796 21 16.1429 21H4.85714C3.02045 21 3 20.8739 3 19.0833V2.91667C3 1.12612 3.02045 1 4.85714 1ZM7 13C7 12.4477 7.44772 12 8 12H15C15.5523 12 16 12.4477 16 13C16 13.5523 15.5523 14 15 14H8C7.44772 14 7 13.5523 7 13ZM8 16C7.44772 16 7 16.4477 7 17C7 17.5523 7.44772 18 8 18H11C11.5523 18 12 17.5523 12 17C12 16.4477 11.5523 16 11 16H8Z"
                            fill="#3182ce"
                          ></path>
                          <path
                            fill-rule="evenodd"
                            clip-rule="evenodd"
                            d="M6.85714 3H14.7364C15.0911 3 15.4343 3.12568 15.7051 3.35474L20.4687 7.38394C20.8057 7.66895 21 8.08788 21 8.5292V21.0833C21 22.8739 20.9796 23 19.1429 23H6.85714C5.02045 23 5 22.8739 5 21.0833V4.91667C5 3.12612 5.02045 3 6.85714 3ZM7 13C7 12.4477 7.44772 12 8 12H15C15.5523 12 16 12.4477 16 13C16 13.5523 15.5523 14 15 14H8C7.44772 14 7 13.5523 7 13ZM8 16C7.44772 16 7 16.4477 7 17C7 17.5523 7.44772 18 8 18H11C11.5523 18 12 17.5523 12 17C12 16.4477 11.5523 16 11 16H8Z"
                            fill="#3182ce"
                          ></path>
                        </svg>
                        <!-- if action type update status -->
                        <svg
                          v-else-if="
                            action.previous_status != action.current_status
                          "
                          width="18"
                          height="18"
                          viewBox="0 0 24 24"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            opacity="0.3"
                            d="M14 3V20H2V3C2 2.4 2.4 2 3 2H13C13.6 2 14 2.4 14 3ZM11 13V11C11 9.7 10.2 8.59995 9 8.19995V7C9 6.4 8.6 6 8 6C7.4 6 7 6.4 7 7V8.19995C5.8 8.59995 5 9.7 5 11V13C5 13.6 4.6 14 4 14V15C4 15.6 4.4 16 5 16H11C11.6 16 12 15.6 12 15V14C11.4 14 11 13.6 11 13Z"
                            fill="#3182ce"
                          ></path>
                          <path
                            d="M2 20H14V21C14 21.6 13.6 22 13 22H3C2.4 22 2 21.6 2 21V20ZM9 3V2H7V3C7 3.6 7.4 4 8 4C8.6 4 9 3.6 9 3ZM6.5 16C6.5 16.8 7.2 17.5 8 17.5C8.8 17.5 9.5 16.8 9.5 16H6.5ZM21.7 12C21.7 11.4 21.3 11 20.7 11H17.6C17 11 16.6 11.4 16.6 12C16.6 12.6 17 13 17.6 13H20.7C21.2 13 21.7 12.6 21.7 12ZM17 8C16.6 8 16.2 7.80002 16.1 7.40002C15.9 6.90002 16.1 6.29998 16.6 6.09998L19.1 5C19.6 4.8 20.2 5 20.4 5.5C20.6 6 20.4 6.60005 19.9 6.80005L17.4 7.90002C17.3 8.00002 17.1 8 17 8ZM19.5 19.1C19.4 19.1 19.2 19.1 19.1 19L16.6 17.9C16.1 17.7 15.9 17.1 16.1 16.6C16.3 16.1 16.9 15.9 17.4 16.1L19.9 17.2C20.4 17.4 20.6 18 20.4 18.5C20.2 18.9 19.9 19.1 19.5 19.1Z"
                            fill="#3182ce"
                          ></path>
                        </svg>
                        <!-- if action type modification -->
                        <svg
                          v-else-if="action.modification != null"
                          width="20"
                          height="20"
                          viewBox="0 0 24 24"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            opacity="0.3"
                            d="M2 4V16C2 16.6 2.4 17 3 17H13L16.6 20.6C17.1 21.1 18 20.8 18 20V17H21C21.6 17 22 16.6 22 16V4C22 3.4 21.6 3 21 3H3C2.4 3 2 3.4 2 4Z"
                            fill="#7abeef"
                          ></path>
                          <path
                            d="M18 9H6C5.4 9 5 8.6 5 8C5 7.4 5.4 7 6 7H18C18.6 7 19 7.4 19 8C19 8.6 18.6 9 18 9ZM16 12C16 11.4 15.6 11 15 11H6C5.4 11 5 11.4 5 12C5 12.6 5.4 13 6 13H15C15.6 13 16 12.6 16 12Z"
                            fill="#3182ce"
                          ></path>
                        </svg>
                        <!-- if action type comment -->
                        <svg
                          v-else-if="action.comment != null"
                          width="20"
                          height="20"
                          viewBox="0 0 24 24"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            opacity="0.3"
                            d="M4.875 20.75C4.63542 20.75 4.39583 20.6542 4.20417 20.4625L2.2875 18.5458C1.90417 18.1625 1.90417 17.5875 2.2875 17.2042C2.67083 16.8208 3.29375 16.8208 3.62917 17.2042L4.875 18.45L8.0375 15.2875C8.42083 14.9042 8.99583 14.9042 9.37917 15.2875C9.7625 15.6708 9.7625 16.2458 9.37917 16.6292L5.54583 20.4625C5.35417 20.6542 5.11458 20.75 4.875 20.75Z"
                            fill="#3182ce"
                          ></path>
                          <path
                            fill-rule="evenodd"
                            clip-rule="evenodd"
                            d="M5 3C3.34315 3 2 4.34315 2 6V11.865C3.04486 11.3127 4.23591 11 5.5 11C9.47412 11 12.7263 14.091 12.9836 18H18.0249L20.3178 20.2929C20.9478 20.9229 22.0249 20.4767 22.0249 19.5858V15L22 15.0098L22 15V6C22 4.34315 20.6569 3 19 3H5ZM6 7C5.44772 7 5 7.44772 5 8C5 8.55228 5.44771 9 6 9H15C15.5523 9 16 8.55228 16 8C16 7.44772 15.5523 7 15 7H6Z"
                            fill="#7abeef"
                          ></path>
                        </svg>
                        <!-- if action type replied comment -->
                        <svg
                          v-else-if="action.replied_comment != null"
                          width="20"
                          height="20"
                          viewBox="0 0 24 24"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            fill-rule="evenodd"
                            clip-rule="evenodd"
                            d="M22.1671 18.1421C22.4827 18.4577 23.0222 18.2331 23.0206 17.7868L23.0039 13.1053V5.52632C23.0039 4.13107 21.8729 3 20.4776 3H8.68815C7.2929 3 6.16183 4.13107 6.16183 5.52632V9H13C14.6568 9 16 10.3431 16 12V15.6316H19.6565L22.1671 18.1421Z"
                            fill="#7abeef"
                          ></path>
                          <path
                            opacity="0.3"
                            fill-rule="evenodd"
                            clip-rule="evenodd"
                            d="M1.98508 18V13C1.98508 11.8954 2.88051 11 3.98508 11H11.9851C13.0896 11 13.9851 11.8954 13.9851 13V18C13.9851 19.1046 13.0896 20 11.9851 20H4.10081L2.85695 21.1905C2.53895 21.4949 2.01123 21.2695 2.01123 20.8293V18.3243C1.99402 18.2187 1.98508 18.1104 1.98508 18ZM5.99999 14.5C5.99999 14.2239 6.22385 14 6.49999 14H11.5C11.7761 14 12 14.2239 12 14.5C12 14.7761 11.7761 15 11.5 15H6.49999C6.22385 15 5.99999 14.7761 5.99999 14.5ZM9.49999 16C9.22385 16 8.99999 16.2239 8.99999 16.5C8.99999 16.7761 9.22385 17 9.49999 17H11.5C11.7761 17 12 16.7761 12 16.5C12 16.2239 11.7761 16 11.5 16H9.49999Z"
                            fill="#3182ce"
                          ></path>
                        </svg>
                        <!-- if action type assign research member -->
                        <svg
                          v-else-if="action.research_assigned_member_id != null"
                          width="20"
                          height="20"
                          viewBox="0 0 24 24"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            opacity="0.3"
                            d="M11 13H7C6.4 13 6 12.6 6 12C6 11.4 6.4 11 7 11H11V13ZM17 11H13V13H17C17.6 13 18 12.6 18 12C18 11.4 17.6 11 17 11Z"
                            fill="#7abeef"
                          ></path>
                          <path
                            d="M22 12C22 17.5 17.5 22 12 22C6.5 22 2 17.5 2 12C2 6.5 6.5 2 12 2C17.5 2 22 6.5 22 12ZM17 11H13V7C13 6.4 12.6 6 12 6C11.4 6 11 6.4 11 7V11H7C6.4 11 6 11.4 6 12C6 12.6 6.4 13 7 13H11V17C11 17.6 11.4 18 12 18C12.6 18 13 17.6 13 17V13H17C17.6 13 18 12.6 18 12C18 11.4 17.6 11 17 11Z"
                            fill="#7abeef"
                          ></path>
                        </svg>
                        <!-- if the action type is uplaoded a new file -->
                        <svg
                          v-if="action.file.length > 0"
                          width="18"
                          height="18"
                          viewBox="0 0 24 24"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            opacity="0.3"
                            d="M4.425 20.525C2.525 18.625 2.525 15.525 4.425 13.525L14.825 3.125C16.325 1.625 18.825 1.625 20.425 3.125C20.825 3.525 20.825 4.12502 20.425 4.52502C20.025 4.92502 19.425 4.92502 19.025 4.52502C18.225 3.72502 17.025 3.72502 16.225 4.52502L5.82499 14.925C4.62499 16.125 4.62499 17.925 5.82499 19.125C7.02499 20.325 8.82501 20.325 10.025 19.125L18.425 10.725C18.825 10.325 19.425 10.325 19.825 10.725C20.225 11.125 20.225 11.725 19.825 12.125L11.425 20.525C9.525 22.425 6.425 22.425 4.425 20.525Z"
                            fill="#3182ce"
                          ></path>
                          <path
                            d="M9.32499 15.625C8.12499 14.425 8.12499 12.625 9.32499 11.425L14.225 6.52498C14.625 6.12498 15.225 6.12498 15.625 6.52498C16.025 6.92498 16.025 7.525 15.625 7.925L10.725 12.8249C10.325 13.2249 10.325 13.8249 10.725 14.2249C11.125 14.6249 11.725 14.6249 12.125 14.2249L19.125 7.22493C19.525 6.82493 19.725 6.425 19.725 5.925C19.725 5.325 19.525 4.825 19.125 4.425C18.725 4.025 18.725 3.42498 19.125 3.02498C19.525 2.62498 20.125 2.62498 20.525 3.02498C21.325 3.82498 21.725 4.825 21.725 5.925C21.725 6.925 21.325 7.82498 20.525 8.52498L13.525 15.525C12.325 16.725 10.525 16.725 9.32499 15.625Z"
                            fill="#2670b6"
                          ></path>
                        </svg>
                      </span>
                    </p>
                    <!-- status if changed -->
                    <span
                      v-if="action.previous_status != action.current_status"
                      class="d-flex flex-row"
                    >
                      <span class="badge bg-soft-danger text-danger fw-bold">{{
                        action.previous_status
                      }}</span>
                      <svg
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M17.4 11H6.60001V13H17.4V11Z"
                          fill="#8b8b8b"
                        ></path>
                        <path
                          opacity="0.3"
                          d="M17.4 7L21.7 11.3C22.1 11.7 22.1 12.3 21.7 12.7L17.4 17V7ZM6.60002 7L2.3 11.3C1.9 11.7 1.9 12.3 2.3 12.7L6.60002 17V7Z"
                          fill="#4b4b4b"
                        ></path>
                      </svg>
                      <span
                        class="badge bg-soft-success text-success fw-bold"
                        >{{ action.current_status }}</span
                      >
                    </span>

                    <!-- if action has report -->
                    <a
                      v-if="action.report != null"
                      class="cursor-p"
                      @click="reportDetails(action.report)"
                    >
                      <span
                        class="badge bg-soft-primary text-primary mx-2 fw-bold"
                      >
                        <a>Review report</a>
                      </span>
                      <svg
                        width="20"
                        height="20"
                        viewBox="0 0 24 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          opacity="0.3"
                          d="M17 6H3C2.4 6 2 6.4 2 7V21C2 21.6 2.4 22 3 22H17C17.6 22 18 21.6 18 21V7C18 6.4 17.6 6 17 6Z"
                          fill="#8b8b8b"
                        ></path>
                        <path
                          d="M17.8 4.79999L9.3 13.3C8.9 13.7 8.9 14.3 9.3 14.7C9.5 14.9 9.80001 15 10 15C10.2 15 10.5 14.9 10.7 14.7L19.2 6.20001L17.8 4.79999Z"
                          fill="#4b4b4b"
                        ></path>
                        <path
                          opacity="0.3"
                          d="M22 9.09998V3C22 2.4 21.6 2 21 2H14.9L22 9.09998Z"
                          fill="#4b4b4b"
                        ></path>
                      </svg>
                    </a>

                    <!-- if action has modification -->
                    <a
                      v-if="action.modification != null"
                      class="cursor-p"
                      @click="modificationDetails(action.modification)"
                    >
                      <span
                        class="badge bg-soft-primary text-primary mx-2 fw-bold"
                      >
                        <a>View modification</a>
                      </span>
                      <svg
                        width="20"
                        height="20"
                        viewBox="0 0 24 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          opacity="0.3"
                          d="M17 6H3C2.4 6 2 6.4 2 7V21C2 21.6 2.4 22 3 22H17C17.6 22 18 21.6 18 21V7C18 6.4 17.6 6 17 6Z"
                          fill="#8b8b8b"
                        ></path>
                        <path
                          d="M17.8 4.79999L9.3 13.3C8.9 13.7 8.9 14.3 9.3 14.7C9.5 14.9 9.80001 15 10 15C10.2 15 10.5 14.9 10.7 14.7L19.2 6.20001L17.8 4.79999Z"
                          fill="#4b4b4b"
                        ></path>
                        <path
                          opacity="0.3"
                          d="M22 9.09998V3C22 2.4 21.6 2 21 2H14.9L22 9.09998Z"
                          fill="#4b4b4b"
                        ></path>
                      </svg>
                    </a>

                    <!-- if action has comment -->
                    <a
                      v-if="action.comment != null"
                      class="cursor-p"
                      @click="commentDetails(action.comment, 'comment')"
                    >
                      <span
                        class="badge bg-soft-primary text-primary mx-2 fw-bold"
                      >
                        <a>View comment</a>
                      </span>
                      <svg
                        width="20"
                        height="20"
                        viewBox="0 0 24 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          opacity="0.3"
                          d="M17 6H3C2.4 6 2 6.4 2 7V21C2 21.6 2.4 22 3 22H17C17.6 22 18 21.6 18 21V7C18 6.4 17.6 6 17 6Z"
                          fill="#8b8b8b"
                        ></path>
                        <path
                          d="M17.8 4.79999L9.3 13.3C8.9 13.7 8.9 14.3 9.3 14.7C9.5 14.9 9.80001 15 10 15C10.2 15 10.5 14.9 10.7 14.7L19.2 6.20001L17.8 4.79999Z"
                          fill="#4b4b4b"
                        ></path>
                        <path
                          opacity="0.3"
                          d="M22 9.09998V3C22 2.4 21.6 2 21 2H14.9L22 9.09998Z"
                          fill="#4b4b4b"
                        ></path>
                      </svg>
                    </a>
                    <!-- if action has replied comment -->
                    <template v-if="action.replied_comment != null">
                      <!-- for the replied comment content -->
                      <a
                        class="cursor-p"
                        @click="commentDetails(action.replied_comment, 'reply')"
                      >
                        <span
                          class="badge bg-soft-primary text-primary mx-2 fw-bold"
                        >
                          <a>View reply</a>
                        </span>
                        <svg
                          width="20"
                          height="20"
                          viewBox="0 0 24 24"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            opacity="0.3"
                            d="M17 6H3C2.4 6 2 6.4 2 7V21C2 21.6 2.4 22 3 22H17C17.6 22 18 21.6 18 21V7C18 6.4 17.6 6 17 6Z"
                            fill="#8b8b8b"
                          ></path>
                          <path
                            d="M17.8 4.79999L9.3 13.3C8.9 13.7 8.9 14.3 9.3 14.7C9.5 14.9 9.80001 15 10 15C10.2 15 10.5 14.9 10.7 14.7L19.2 6.20001L17.8 4.79999Z"
                            fill="#4b4b4b"
                          ></path>
                          <path
                            opacity="0.3"
                            d="M22 9.09998V3C22 2.4 21.6 2 21 2H14.9L22 9.09998Z"
                            fill="#4b4b4b"
                          ></path>
                        </svg>
                      </a>
                      <b class="mx-3 text-muted">|</b>
                      <!-- for the parent comment content -->
                      <a
                        class="cursor-p"
                        @click="
                          commentDetails(
                            action.replied_comment.comment,
                            'original comment'
                          )
                        "
                      >
                        <span class="badge bg-soft-dark text-dark mx-2 fw-bold">
                          <a>View original comment</a>
                        </span>
                        <svg
                          width="20"
                          height="20"
                          viewBox="0 0 24 24"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            opacity="0.3"
                            d="M17 6H3C2.4 6 2 6.4 2 7V21C2 21.6 2.4 22 3 22H17C17.6 22 18 21.6 18 21V7C18 6.4 17.6 6 17 6Z"
                            fill="#8b8b8b"
                          ></path>
                          <path
                            d="M17.8 4.79999L9.3 13.3C8.9 13.7 8.9 14.3 9.3 14.7C9.5 14.9 9.80001 15 10 15C10.2 15 10.5 14.9 10.7 14.7L19.2 6.20001L17.8 4.79999Z"
                            fill="#4b4b4b"
                          ></path>
                          <path
                            opacity="0.3"
                            d="M22 9.09998V3C22 2.4 21.6 2 21 2H14.9L22 9.09998Z"
                            fill="#4b4b4b"
                          ></path>
                        </svg>
                      </a>
                    </template>
                    <!-- if action has suspension -->
                    <a
                      v-if="action.suspension != null"
                      class="cursor-p"
                      @click="suspensionDetails(action.suspension)"
                    >
                      <span
                        class="badge bg-soft-warning text-warning mt-3 mx-2 fw-bold"
                      >
                        <a>View suspension</a>
                      </span>
                      <svg
                        width="20"
                        height="20"
                        viewBox="0 0 24 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          opacity="0.3"
                          d="M17 6H3C2.4 6 2 6.4 2 7V21C2 21.6 2.4 22 3 22H17C17.6 22 18 21.6 18 21V7C18 6.4 17.6 6 17 6Z"
                          fill="#8b8b8b"
                        ></path>
                        <path
                          d="M17.8 4.79999L9.3 13.3C8.9 13.7 8.9 14.3 9.3 14.7C9.5 14.9 9.80001 15 10 15C10.2 15 10.5 14.9 10.7 14.7L19.2 6.20001L17.8 4.79999Z"
                          fill="#4b4b4b"
                        ></path>
                        <path
                          opacity="0.3"
                          d="M22 9.09998V3C22 2.4 21.6 2 21 2H14.9L22 9.09998Z"
                          fill="#4b4b4b"
                        ></path>
                      </svg>
                    </a>
                    <!-- if the action has files -->
                    <ul
                      class="list-group list-group-sm"
                      v-if="action.file.length > 0"
                    >
                      <!-- List Item -->
                      <li
                        class="list-group-item list-group-item-light"
                        v-for="file in action.file"
                        :key="file.id"
                      >
                        <div class="d-flex">
                          <!-- file icon -->
                          <div class="flex-shrink-0 me-2">
                            <svg
                              width="18"
                              height="18"
                              viewBox="0 0 24 24"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                opacity="0.3"
                                d="M4.425 20.525C2.525 18.625 2.525 15.525 4.425 13.525L14.825 3.125C16.325 1.625 18.825 1.625 20.425 3.125C20.825 3.525 20.825 4.12502 20.425 4.52502C20.025 4.92502 19.425 4.92502 19.025 4.52502C18.225 3.72502 17.025 3.72502 16.225 4.52502L5.82499 14.925C4.62499 16.125 4.62499 17.925 5.82499 19.125C7.02499 20.325 8.82501 20.325 10.025 19.125L18.425 10.725C18.825 10.325 19.425 10.325 19.825 10.725C20.225 11.125 20.225 11.725 19.825 12.125L11.425 20.525C9.525 22.425 6.425 22.425 4.425 20.525Z"
                                fill="#3182ce"
                              ></path>
                              <path
                                d="M9.32499 15.625C8.12499 14.425 8.12499 12.625 9.32499 11.425L14.225 6.52498C14.625 6.12498 15.225 6.12498 15.625 6.52498C16.025 6.92498 16.025 7.525 15.625 7.925L10.725 12.8249C10.325 13.2249 10.325 13.8249 10.725 14.2249C11.125 14.6249 11.725 14.6249 12.125 14.2249L19.125 7.22493C19.525 6.82493 19.725 6.425 19.725 5.925C19.725 5.325 19.525 4.825 19.125 4.425C18.725 4.025 18.725 3.42498 19.125 3.02498C19.525 2.62498 20.125 2.62498 20.525 3.02498C21.325 3.82498 21.725 4.825 21.725 5.925C21.725 6.925 21.325 7.82498 20.525 8.52498L13.525 15.525C12.325 16.725 10.525 16.725 9.32499 15.625Z"
                                fill="#2670b6"
                              ></path>
                            </svg>
                          </div>
                          <!-- file name -->
                          <div class="flex-grow-1 text-truncate">
                            <span class="d-block text-dark text-truncate">
                              <a
                                class="cursor-p fw-bold"
                                @click="downloadFile(file.storage_path)"
                              >
                                {{ file.storage_path.split("/")[1] }}
                              </a>
                            </span>
                          </div>
                        </div>
                      </li>
                      <!-- End List Item -->
                    </ul>
                  </div>
                </div>
              </li>
              <!-- End Step Item -->

              <!-- Step Item (end)-->
              <li class="step-item" v-if="index == actions.length - 1">
                <div class="step-content-wrapper">
                  <small class="fw-bold text-center text-muted w-100 fs-5 mt-4"
                    >End</small
                  >
                </div>
              </li>
              <!-- End Step Item -->
            </template>
          </ul>
          <!-- End Step -->
        </div>
      </div>
    </div>
    <!-- no results found -->
    <div class="container" v-else-if="actions.length == 0 && !in_submission">
      <div class="row">
        <div class="col-auto text-center mt-5 pt-5">
          <img src="/img/oc-error.jpg" class="w-25" alt="no actions" />
          <p class="text-muted fw-bold fs-5 pt-5 text-center">
            no results found.
          </p>
        </div>
      </div>
    </div>
    <report-info-modal :report="currentReport" />
    <modification-info-modal
      :modification="currentModification"
      :showQuilEditor="showQuilEditor"
      :rerenderQuill="rerenderQuill"
    />
    <reviewCommentOrReplyModal
      :commentType="CurrentCommentType"
      :commentOrReplay="currentCommentOrReply"
    />
    <suspension-info-modal :suspension="currentSuspension" />
  </div>
</template>

<script>
import moment from "moment";
import reportInfoModal from "@/components/adminsActions/reportInfoModal.vue";
import modificationInfoModal from "@/components/modifications/modificationInfoModal.vue";
import suspensionInfoModal from "@/components/suspensions/suspensionInfoModal.vue";
import reviewCommentOrReplyModal from "@/components/forum/reviewCommentOrReplyModal";
import axiosConfig from "@/includes/axiosConfig";
import { downloadFile, hasRole } from "@/includes/helpers.js";
import { mapGetters } from "vuex";

export default {
  name: "RecActions",
  components: {
    reportInfoModal,
    modificationInfoModal,
    suspensionInfoModal,
    reviewCommentOrReplyModal,
  },
  computed: {
    ...mapGetters({
      user: "user",
    }),
  },
  props: {
    getResearchInfo: {
      required: false,
      type: Function,
    },
    research: {
      required: true,
      type: [null, Object],
    },
    loadingResearch: {
      required: false,
      type: Boolean,
    },
  },
  data() {
    return {
      in_submission: false,
      showQuilEditor: false,
      actions: [],
      currentReport: {},
      currentModification: {},
      currentSuspension: {},
      currentCommentOrReply: {},
      CurrentCommentType: "",
    };
  },
  methods: {
    async fetchActions(
      role = this.user.userRoles.includes("admin")
        ? "%"
        : this.user.userRoles[0].split("-")[0]
    ) {
      this.in_submission = true;
      let researchId = this.$route.params.Id;
      let vals = {};
      vals.role = role;
      await axiosConfig
        .post(`api/fetchActions/${researchId}`, vals)
        .then((res) => {
          this.actions = res.data;
          console.log(res);
        })
        .catch((err) => {
          console.log(err.response);
        });
      this.in_submission = false;
    },
    formatDate(date) {
      var today = moment(new Date()).format("MM-DD-YYYY");
      if (date == today) {
        return "today";
      } else if (moment(date).isSame(new Date(), "week")) {
        return "this week";
      } else if (moment(date).isSame(new Date(), "month")) {
        return "this month";
      } else if (moment(date).isSame(new Date(), "year")) {
        return "this year";
      }
    },
    checkSameDate(action, previosAction) {
      let sameDate = true;
      if (previosAction != null && previosAction != undefined) {
        if (
          this.formatDate(action.created_at) ==
          this.formatDate(previosAction.created_at)
        ) {
          sameDate = false;
        }
      }
      return sameDate;
    },
    reportDetails(report) {
      this.currentReport = report;
      document.getElementById("reportInfoModalBtn").click();
    },
    modificationDetails(modification) {
      this.currentModification = modification;
      this.rerenderQuill();
      document.getElementById("modificationInfoModalBtn").click();
    },
    commentDetails(comment, commentType) {
      this.currentCommentOrReply = comment;
      this.CurrentCommentType = commentType;
      document.getElementById("reviewCommentOrReplyButton").click();
    },
    suspensionDetails(suspension) {
      this.currentSuspension = suspension;
      document.getElementById("suspensionInfoModalBtn").click();
    },
    async filterActions({ role = "%" }) {
      if (!this.in_submission) {
        await this.fetchActions(role);
      }
    },
    //strange behiavoir of quill that it's must be rerendered to be updated....
    rerenderQuill() {
      this.showQuilEditor = !this.showQuilEditor;
    },
    downloadFile,
    hasRole,
  },
  activated() {
    this.actions.length == 0 && !this.in_submission ? this.fetchActions() : "";
  },
  watch: {
    research() {
      this.fetchActions();
    },
  },
};
</script>

<style scoped>
.step-text p {
  margin: 0 !important;
}
</style>
