<template>
  <!-- Button trigger modal -->
  <button
    type="button"
    class="d-none"
    data-bs-toggle="modal"
    id="suspendModalButton"
    data-bs-target="#suspendModal"
  ></button>

  <!-- Modal -->
  <div
    class="modal fade"
    id="suspendModal"
    tabindex="-1"
    aria-labelledby="suspendModalLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="suspendModalLabel">
            confirmation message
          </h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
            id="closeToggleSuspendModalBTN"
          ></button>
        </div>

        <vee-form @submit="toggleSuspend" :validationSchema="suspensionSchema">
          <div class="modal-body">
            <p>
              are you sure that you want to
              {{ researchStatus.name == "Suspended" ? "unSuspend" : "suspend" }}
              the research ?
            </p>
            <div class="mb-3" v-if="researchStatus.name != 'Suspended'">
              <label class="form-label" for="suspensionNote">notes</label>
              <vee-field
                as="textarea"
                id="suspensionNote"
                class="form-control"
                placeholder="put your notes about this suspension..."
                rows="4"
                name="suspensionNote"
              />
              <error-message name="suspensionNote" class="text-danger" />
            </div>
          </div>
          <div class="modal-footer">
            <button v-if="!in_submission" type="submit" class="btn btn-primary">
              yes
            </button>
            <div
              class="spinner-border text-primary"
              role="status"
              v-if="in_submission"
            >
              <span class="visually-hidden">Loading...</span>
            </div>
            <button type="button" class="btn btn-white" data-bs-dismiss="modal">
              close
            </button>
          </div>
        </vee-form>
      </div>
    </div>
  </div>
</template>

<script>
import axiosConfig from "@/includes/axiosConfig.js";

export default {
  name: "toggleSuspend",
  props: {
    researchId: {
      type: Number,
      required: true,
    },
    researchStatus: {
      type: Object,
      required: true,
    },
    getResearchInfo: {
      type: Function,
      required: true,
    },
  },
  data() {
    return {
      in_submission: false,
      suspensionSchema: { suspensionNote: "max:200" },
    };
  },
  methods: {
    async toggleSuspend(val) {
      console.log(val);
      this.in_submission = true;
      await axiosConfig
        .post(`api/toggleSuspend/${this.researchId}`, val)
        .then(() => {
          this.getResearchInfo();
          document.getElementById("closeToggleSuspendModalBTN").click();
        })
        .catch((err) => {
          console.log(err.response);
        });
      this.in_submission = false;
    },
  },
};
</script>

<style></style>
