<template>
  <!-- Button trigger modal -->
  <button
    type="button"
    class="btn btn-primary d-none"
    data-bs-toggle="modal"
    data-bs-target="#rejectProposalModel"
    id="rejectProposalModelBtn"
  ></button>
  <!-- End Button trigger modal -->

  <!-- Modal -->
  <div
    id="rejectProposalModel"
    class="modal fade"
    data-bs-backdrop="static"
    data-bs-keyboard="false"
    tabindex="-1"
    role="dialog"
    aria-labelledby="rejectProposalModelTitle"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-dialog-centered" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="rejectProposalModelTitle">
            Rejection confirmation
          </h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
            id="closeRejectProposalModal"
          ></button>
        </div>
        <div class="modal-body">
          <!-- instructions -->
          <p>
            If you reject this proposal you will never be able to interact with
            it again and his status will be Rejected forever
          </p>
          <p>Are you agree with that ?</p>
          <!-- end instructions -->
        </div>
        <div class="modal-footer">
          <p
            class="fw-bold d-block w-100 mb-2"
            :class="messageClass"
            v-if="message.length > 0"
          >
            {{ message }}
          </p>
          <button
            v-if="!in_submission"
            type="button"
            class="btn btn-danger"
            @click="rejectTheProposal"
          >
            Yes, reject the proposal
          </button>
          <div
            class="spinner-border m-5 text-primary"
            role="status"
            v-if="in_submission"
          >
            <span class="visually-hidden">Loading...</span>
          </div>
          <button type="button" class="btn btn-white" data-bs-dismiss="modal">
            Close
          </button>
        </div>
      </div>
    </div>
  </div>
  <!-- End Modal -->
</template>

<script>
import axiosConfig from "@/includes/axiosConfig.js";

export default {
  name: "rejectProposalModal",
  props: {
    research: {
      type: Object,
      required: true,
    },
    getResearchInfo: {
      type: Function,
      required: true,
    },
  },
  data() {
    return {
      in_submission: false,
      message: "",
      messageClass: "",
    };
  },
  methods: {
    async rejectTheProposal() {
      this.in_submission = true;
      await axiosConfig
        .get(`api/rejectProposal/${this.research.id}`)
        .then((res) => {
          this.messageClass = "text-success";
          this.message =
            "the proposal has been rejected successfully, we will notify the researcher about that actions.";
          this.getResearchInfo();
          setTimeout(() => {
            document.getElementById("closeRejectProposalModal").click();
          }, 4000);
          console.log(res);
        })
        .catch((err) => {
          this.messageClass = "text-danger";
          this.message = "An error has been occured please try again later.";
          console.log(err.response);
        });
      this.in_submission = false;
    },
  },
};
</script>
