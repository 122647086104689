<template>
  <RRSheader />
  <page-title :refresh="getResearchInfo" :in_submission="loadingResearch">
    <template v-slot:titlePrefix>Research </template>
    <template v-slot:mainTitle>Lifecycle </template>
  </page-title>
  <!-- Nav -->
  <div class="container-fluid content-space-1 px-">
    <div class="row">
      <div class="col-sm-12 col-md-4 col-lg-3">
        <!-- Nav -->
        <ul
          class="nav nav-tabs nav-link-gray nav-vertical"
          id="featuresTab"
          role="tablist"
        >
          <!-- research details -->
          <li class="nav-item py-3 cursor-p">
            <a
              class="nav-link"
              :class="{ active: currentTab == 'researchDetails' }"
              @click="currentTab = 'researchDetails'"
              style="width: 22rem"
            >
              <div class="d-flex">
                <div class="flex-shrink-0">
                  <svg
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      opacity="0.3"
                      d="M2 4V16C2 16.6 2.4 17 3 17H13L16.6 20.6C17.1 21.1 18 20.8 18 20V17H21C21.6 17 22 16.6 22 16V4C22 3.4 21.6 3 21 3H3C2.4 3 2 3.4 2 4Z"
                      fill="#2670b6"
                    ></path>
                    <path
                      d="M18 9H6C5.4 9 5 8.6 5 8C5 7.4 5.4 7 6 7H18C18.6 7 19 7.4 19 8C19 8.6 18.6 9 18 9ZM16 12C16 11.4 15.6 11 15 11H6C5.4 11 5 11.4 5 12C5 12.6 5.4 13 6 13H15C15.6 13 16 12.6 16 12Z"
                      fill="#ffff"
                    ></path>
                  </svg>
                </div>

                <div class="flex-grow-1 ms-3">
                  <h6 class="nav-title d-inline">Research Details</h6>
                </div>
              </div>
            </a>
          </li>
          <!-- research roadMap -->
          <li class="nav-item py-3">
            <a
              class="nav-link cursor-p"
              :class="{
                active: currentTab == 'roadMap',
                disabled: loadingResearch,
              }"
              @click="currentTab = 'roadMap'"
              style="width: 22rem"
            >
              <div class="d-flex">
                <div class="flex-shrink-0">
                  <svg
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      opacity="0.3"
                      d="M18.0624 15.3453L13.1624 20.7453C12.5624 21.4453 11.5624 21.4453 10.9624 20.7453L6.06242 15.3453C4.56242 13.6453 3.76242 11.4453 4.06242 8.94534C4.56242 5.34534 7.46242 2.44534 11.0624 2.04534C15.8624 1.54534 19.9624 5.24534 19.9624 9.94534C20.0624 12.0453 19.2624 13.9453 18.0624 15.3453Z"
                      fill="#2670b6"
                    ></path>
                    <path
                      d="M12.0624 13.0453C13.7193 13.0453 15.0624 11.7022 15.0624 10.0453C15.0624 8.38849 13.7193 7.04535 12.0624 7.04535C10.4056 7.04535 9.06241 8.38849 9.06241 10.0453C9.06241 11.7022 10.4056 13.0453 12.0624 13.0453Z"
                      fill="#3182ce"
                    ></path>
                  </svg>
                </div>
                <div class="flex-grow-1 ms-3">
                  <h6 class="nav-title">Research Road Map</h6>
                </div>
              </div>
            </a>
          </li>
          <!-- research actions -->
          <li
            class="nav-item py-3"
            v-if="
              hasRole([
                'RSC-admin',
                'REC-admin',
                'RSC-member',
                'REC-member',
                'admin',
              ])
            "
          >
            <a
              class="nav-link cursor-p"
              :class="{
                active: currentTab == 'actionsLog',
                disabled: loadingResearch,
              }"
              @click="currentTab = 'actionsLog'"
              style="width: 22rem"
            >
              <div class="d-flex">
                <div class="flex-shrink-0">
                  <svg
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M18.0002 22H6.00019C5.20019 22 4.8002 21.1 5.2002 20.4L12.0002 12L18.8002 20.4C19.3002 21.1 18.8002 22 18.0002 22Z"
                      fill="#2670b6"
                    ></path>
                    <path
                      opacity="0.3"
                      d="M18.8002 3.6L12.0002 12L5.20019 3.6C4.70019 3 5.20018 2 6.00018 2H18.0002C18.8002 2 19.3002 2.9 18.8002 3.6Z"
                      fill="#3182ce"
                    ></path>
                  </svg>
                </div>
                <div class="flex-grow-1 ms-3">
                  <h6 class="nav-title">Research Actions Log</h6>
                </div>
              </div>
            </a>
          </li>
          <!-- modification -->
          <li class="nav-item py-3" v-if="hasRole(['researcher'])">
            <a
              class="nav-link cursor-p"
              :class="{
                active: currentTab == 'modificationsMain',
                disabled: loadingResearch,
              }"
              @click="currentTab = 'modificationsMain'"
              style="width: 22rem"
            >
              <div class="d-flex">
                <div class="flex-shrink-0">
                  <svg
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      opacity="0.3"
                      d="M14 3V20H2V3C2 2.4 2.4 2 3 2H13C13.6 2 14 2.4 14 3ZM11 13V11C11 9.7 10.2 8.59995 9 8.19995V7C9 6.4 8.6 6 8 6C7.4 6 7 6.4 7 7V8.19995C5.8 8.59995 5 9.7 5 11V13C5 13.6 4.6 14 4 14V15C4 15.6 4.4 16 5 16H11C11.6 16 12 15.6 12 15V14C11.4 14 11 13.6 11 13Z"
                      fill="#3182ce"
                    ></path>
                    <path
                      d="M2 20H14V21C14 21.6 13.6 22 13 22H3C2.4 22 2 21.6 2 21V20ZM9 3V2H7V3C7 3.6 7.4 4 8 4C8.6 4 9 3.6 9 3ZM6.5 16C6.5 16.8 7.2 17.5 8 17.5C8.8 17.5 9.5 16.8 9.5 16H6.5ZM21.7 12C21.7 11.4 21.3 11 20.7 11H17.6C17 11 16.6 11.4 16.6 12C16.6 12.6 17 13 17.6 13H20.7C21.2 13 21.7 12.6 21.7 12ZM17 8C16.6 8 16.2 7.80002 16.1 7.40002C15.9 6.90002 16.1 6.29998 16.6 6.09998L19.1 5C19.6 4.8 20.2 5 20.4 5.5C20.6 6 20.4 6.60005 19.9 6.80005L17.4 7.90002C17.3 8.00002 17.1 8 17 8ZM19.5 19.1C19.4 19.1 19.2 19.1 19.1 19L16.6 17.9C16.1 17.7 15.9 17.1 16.1 16.6C16.3 16.1 16.9 15.9 17.4 16.1L19.9 17.2C20.4 17.4 20.6 18 20.4 18.5C20.2 18.9 19.9 19.1 19.5 19.1Z"
                      fill="#7abeef"
                    ></path>
                  </svg>
                </div>
                <div class="flex-grow-1 ms-3">
                  <h6 class="nav-title">Research Modifications</h6>
                </div>
              </div>
            </a>
          </li>
          <!-- assigned members -->
          <li
            class="nav-item py-3"
            v-if="
              hasRole(['REC-admin', 'RSC-admin', 'REC-member', 'RSC-member'])
            "
          >
            <a
              class="nav-link cursor-p"
              :class="{
                active: currentTab == 'assignedMembersMain',
                disabled: loadingResearch,
              }"
              @click="currentTab = 'assignedMembersMain'"
              style="width: 22rem"
            >
              <div class="d-flex">
                <div class="flex-shrink-0">
                  <svg
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M20 14H18V10H20C20.6 10 21 10.4 21 11V13C21 13.6 20.6 14 20 14ZM21 19V17C21 16.4 20.6 16 20 16H18V20H20C20.6 20 21 19.6 21 19ZM21 7V5C21 4.4 20.6 4 20 4H18V8H20C20.6 8 21 7.6 21 7Z"
                      fill="#2670b6"
                    ></path>
                    <path
                      opacity="0.3"
                      d="M17 22H3C2.4 22 2 21.6 2 21V3C2 2.4 2.4 2 3 2H17C17.6 2 18 2.4 18 3V21C18 21.6 17.6 22 17 22ZM10 7C8.9 7 8 7.9 8 9C8 10.1 8.9 11 10 11C11.1 11 12 10.1 12 9C12 7.9 11.1 7 10 7ZM13.3 16C14 16 14.5 15.3 14.3 14.7C13.7 13.2 12 12 10.1 12C8.10001 12 6.49999 13.1 5.89999 14.7C5.59999 15.3 6.19999 16 7.39999 16H13.3Z"
                      fill="#3182ce"
                    ></path>
                  </svg>
                </div>
                <div class="flex-grow-1 ms-3">
                  <h6 class="nav-title">Research Members</h6>
                </div>
              </div>
            </a>
          </li>
          <!-- forum -->
          <li
            class="nav-item py-3"
            v-if="
              hasRole(['REC-admin', 'RSC-admin', 'REC-member', 'RSC-member'])
            "
          >
            <a
              class="nav-link cursor-p"
              :class="{
                active: currentTab == 'forumMain',
                disabled: loadingResearch,
              }"
              @click="currentTab = 'forumMain'"
              style="width: 22rem"
            >
              <div class="d-flex">
                <div class="flex-shrink-0">
                  <svg
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M22.1671 18.1421C22.4827 18.4577 23.0222 18.2331 23.0206 17.7868L23.0039 13.1053V5.52632C23.0039 4.13107 21.8729 3 20.4776 3H8.68815C7.2929 3 6.16183 4.13107 6.16183 5.52632V9H13C14.6568 9 16 10.3431 16 12V15.6316H19.6565L22.1671 18.1421Z"
                      fill="#2670b6"
                    ></path>
                    <path
                      opacity="0.3"
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M1.98508 18V13C1.98508 11.8954 2.88051 11 3.98508 11H11.9851C13.0896 11 13.9851 11.8954 13.9851 13V18C13.9851 19.1046 13.0896 20 11.9851 20H4.10081L2.85695 21.1905C2.53895 21.4949 2.01123 21.2695 2.01123 20.8293V18.3243C1.99402 18.2187 1.98508 18.1104 1.98508 18ZM5.99999 14.5C5.99999 14.2239 6.22385 14 6.49999 14H11.5C11.7761 14 12 14.2239 12 14.5C12 14.7761 11.7761 15 11.5 15H6.49999C6.22385 15 5.99999 14.7761 5.99999 14.5ZM9.49999 16C9.22385 16 8.99999 16.2239 8.99999 16.5C8.99999 16.7761 9.22385 17 9.49999 17H11.5C11.7761 17 12 16.7761 12 16.5C12 16.2239 11.7761 16 11.5 16H9.49999Z"
                      fill="#3182ce"
                    ></path>
                  </svg>
                </div>
                <div class="flex-grow-1 ms-3">
                  <h6 class="nav-title">Research Forum</h6>
                </div>
              </div>
            </a>
          </li>
          <!-- reports -->
          <li class="nav-item py-3">
            <a
              class="nav-link cursor-p"
              :class="{
                active: currentTab == 'ReportsMain',
                disabled: loadingResearch,
              }"
              @click="currentTab = 'ReportsMain'"
              style="width: 22rem"
            >
              <div class="d-flex">
                <div class="flex-shrink-0">
                  <svg
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      opacity="0.3"
                      d="M4.875 20.75C4.63542 20.75 4.39583 20.6542 4.20417 20.4625L2.2875 18.5458C1.90417 18.1625 1.90417 17.5875 2.2875 17.2042C2.67083 16.8208 3.29375 16.8208 3.62917 17.2042L4.875 18.45L8.0375 15.2875C8.42083 14.9042 8.99583 14.9042 9.37917 15.2875C9.7625 15.6708 9.7625 16.2458 9.37917 16.6292L5.54583 20.4625C5.35417 20.6542 5.11458 20.75 4.875 20.75Z"
                      fill="#2670b6"
                    ></path>
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M5 3C3.34315 3 2 4.34315 2 6V11.865C3.04486 11.3127 4.23591 11 5.5 11C9.47412 11 12.7263 14.091 12.9836 18H18.0249L20.3178 20.2929C20.9478 20.9229 22.0249 20.4767 22.0249 19.5858V15L22 15.0098L22 15V6C22 4.34315 20.6569 3 19 3H5ZM6 7C5.44772 7 5 7.44772 5 8C5 8.55228 5.44771 9 6 9H15C15.5523 9 16 8.55228 16 8C16 7.44772 15.5523 7 15 7H6Z"
                      fill="#3182ce"
                    ></path>
                  </svg>
                </div>
                <div class="flex-grow-1 ms-3">
                  <h6 class="nav-title">Research Reports</h6>
                </div>
              </div>
            </a>
          </li>
        </ul>
        <!-- End Nav -->
      </div>
      <!-- End Col -->

      <div class="col-sm-12 col-md-8 col-lg-9">
        <div class="tab-content" id="featuresTabContent">
          <keep-alive>
            <component
              :is="currentTab"
              :getResearchInfo="getResearchInfo"
              :research="research"
              :loadingResearch="loadingResearch"
            />
          </keep-alive>
        </div>
        <!-- End Col -->
      </div>
      <!-- End Row -->
    </div>
  </div>
  <!-- End Nav -->
  <popUpMessage />
</template>

<script>
import pageTitle from "@/components/pageTitle.vue";
import RRSheader from "./RRSheader.vue";
import researchDetails from "@/components/research/researchDetails.vue";
import ReportsMain from "@/components/reports/reportsMain.vue";
import actionsLog from "@/components/adminsActions/actionsLog.vue";
import roadMap from "@/components/roadMap/roadMap.vue";
import modificationsMain from "@/components/modifications/modificationsMain.vue";
import forumMain from "@/components/forum/forumMain.vue";
import assignedMembersMain from "@/components/assignedMembers/assignedMembersMain.vue";
import store from "@/store";
import axiosConfig from "@/includes/axiosConfig";
import { hasRole } from "@/includes/helpers";
import { mapGetters } from "vuex";

export default {
  name: "researchPage",
  components: {
    pageTitle,
    RRSheader,
    researchDetails,
    ReportsMain,
    actionsLog,
    roadMap,
    modificationsMain,
    forumMain,
    assignedMembersMain,
  },
  computed: {
    ...mapGetters({
      user: "user",
    }),
  },
  data() {
    return {
      currentTab: "researchDetails",
      research: null,
      loadingResearch: false,
    };
  },
  methods: {
    async getResearchInfo() {
      this.loadingResearch = true;
      let id = this.$route.params.Id;
      await axiosConfig
        .get(`api/researchInfo/${id}`)
        .then((res) => {
          this.research = res.data;
          console.log(res);
        })
        .catch((err) => {
          console.log(err.response);
          if (err.response.status == 404) {
            this.$router.replace({ name: "main" });
          }
          return;
        });
      this.loadingResearch = false;
    },
    hasRole,
  },
  async created() {
    await this.getResearchInfo();
  },
  beforeRouteEnter(to, from, next) {
    if (!store.state.authenticated) {
      next({ name: "Home" });
    } else if (!store.state.verified) {
      next({ name: "verifyEmail" });
    } else if (
      !hasRole([
        "admin",
        "REC-admin",
        "RSC-admin",
        "REC-member",
        "RSC-member",
      ]) &&
      !store.state.user.researches_ids.includes(parseInt(to.params.Id))
    ) {
      store.dispatch("changeGlobalAlertMessage", "This action is forbidden");
      store.dispatch("changeGlobalAlertClass", "alert-danger");
      store.dispatch("changeShowPopUpMessage", true);
      next({ name: "main" });
      setTimeout(() => {
        store.dispatch("changeShowPopUpMessage", false);
      }, 3000);
    } else {
      next();
    }
  },
  beforeRouteUpdate(to, from, next) {
    if (
      !hasRole([
        "admin",
        "REC-admin",
        "RSC-admin",
        "REC-member",
        "RSC-member",
      ]) &&
      !store.state.user.researches_ids.includes(parseInt(to.params.Id))
    ) {
      store.dispatch("changeGlobalAlertMessage", "This action is forbidden");
      store.dispatch("changeGlobalAlertClass", "alert-danger");
      store.dispatch("changeShowPopUpMessage", true);
      setTimeout(() => {
        store.dispatch("changeShowPopUpMessage", false);
      }, 3000);
    } else {
      next();
    }
  },
  watch: {
    async $route(newVal, oldVal) {
      if (newVal != oldVal && newVal.name == "research") {
        await this.getResearchInfo();
      }
    },
  },
};
</script>
