<template>
  <!-- title -->
  <div class="mb-3">
    <!-- uplaod Report -->
    <div class="row">
      <div class="col-md-3 col-sm-12 col-form-label">
        <label for="" class="d-none d-md-block d-lg-block"
          >{{
            this.finalReport
              ? "Upload Your Published Research"
              : "Upload your Report"
          }}<i class="text-danger">*</i></label
        >
      </div>
      <div class="col-md-8 col-sm-12 col-8">
        <div class="area d-none d-md-block d-lg-block">
          <div
            :class="{ 'dragOver-dropZone': isDragOver }"
            id="dropZone"
            @drag.prevent.stop=""
            @dragstart.prevent.stop=""
            @dragend.prevent.stop="isDragOver = false"
            @dragover.prevent.stop="isDragOver = true"
            @dragenter.prevent.stop="isDragOver = true"
            @dragleave.prevent.stop="isDragOver = false"
            @drop.prevent.stop="attach($event)"
            @click="attachReportManualy"
          >
            {{ !this.finalReport ? "Drop Your Report" : "Drop Your Research" }}
          </div>
        </div>
      </div>
    </div>
    <!-- Form for uplaoding attach manualy only on sm and xs screen-->
    <div class="row my-3 d-block d-xl-none d-lg-none d-md-none">
      <label for="uploadReportSM" class="col-sm col-form-label"
        >{{ this.finalReport ? "Drop your Research" : "Drop your Report"
        }}<i class="text-danger">*</i></label
      >
      <div class="col-sm-9">
        <input
          type="file"
          class="form-control"
          id="uploadReportSM"
          name="uploadReportSM"
          placeholder=""
          aria-label="example@site.com"
          @change="attach($event)"
        />
      </div>
    </div>
    <!-- uplaod Report ends here -->
    <!-- attached file list -->
    <div v-if="report.length > 0">
      <b>{{
        !this.finalReport ? "Your uploaded Report:" : "Your uploaded Research"
      }}</b>
      <ul class="text-primary">
        <li>
          {{ report[0].name }}
          <!-- remove file button -->
          <a class="cursor-p mx-2" @click="setReport(null)">
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                opacity="0.3"
                d="M12 10.6L14.8 7.8C15.2 7.4 15.8 7.4 16.2 7.8C16.6 8.2 16.6 8.80002 16.2 9.20002L13.4 12L12 10.6ZM10.6 12L7.8 14.8C7.4 15.2 7.4 15.8 7.8 16.2C8 16.4 8.3 16.5 8.5 16.5C8.7 16.5 8.99999 16.4 9.19999 16.2L12 13.4L10.6 12Z"
                fill="#692340"
              ></path>
              <path
                d="M22 12C22 17.5 17.5 22 12 22C6.5 22 2 17.5 2 12C2 6.5 6.5 2 12 2C17.5 2 22 6.5 22 12ZM13.4 12L16.2 9.20001C16.6 8.80001 16.6 8.19999 16.2 7.79999C15.8 7.39999 15.2 7.39999 14.8 7.79999L12 10.6L9.2 7.79999C8.8 7.39999 8.2 7.39999 7.8 7.79999C7.4 8.19999 7.4 8.80001 7.8 9.20001L10.6 12L7.8 14.8C7.4 15.2 7.4 15.8 7.8 16.2C8 16.4 8.3 16.5 8.5 16.5C8.7 16.5 9 16.4 9.2 16.2L12 13.4L14.8 16.2C15 16.4 15.3 16.5 15.5 16.5C15.7 16.5 16 16.4 16.2 16.2C16.6 15.8 16.6 15.2 16.2 14.8L13.4 12Z"
                fill="#692340"
              ></path>
            </svg>
          </a>
        </li>
      </ul>
    </div>
    <!-- end attached file list -->
    <!-- message -->
    <p
      class="fw-bold d-block w-100 mb-2"
      :class="messageClass"
      v-if="message.length > 0"
    >
      {{ message }}
    </p>
  </div>
</template>

<script>
export default {
  name: "uploadReport",
  props: {
    report: {
      type: [Array, null],
      required: true,
    },
    setReport: {
      type: Function,
      required: true,
    },
    finalReport: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      isDragOver: false,
      acceptedType: [],
      in_submission: false,
      message: "",
      messageClass: "",
    };
  },
  methods: {
    attach($event) {
      this.setReport(null);
      this.isDragOver = false;
      const files = $event.dataTransfer
        ? [...$event.dataTransfer.files]
        : [...$event.target.files];
      if (
        files.length == 1 &&
        this.acceptedType.includes(files[0].type) &&
        files[0].size / 1000 / 1000 <= (this.finalReport ? 10 : 2)
      ) {
        this.setReport(files[0]);
      } else {
        this.messageClass = "text-danger";
        this.message =
          "please make sure that you have uploaded 1 file that has PDF or word document extensions and the maximum size is 1 MB";
        setTimeout(() => {
          this.message = "";
        }, 6000);
      }
      document.getElementById("uploadReportSM").value = "";
    },
    attachReportManualy() {
      document.getElementById("uploadReportSM").click();
    },
  },
  created() {
    this.acceptedType = [
      "application/pdf",
      "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
      "application/vnd.openxmlformats-officedocument.wordprocessingml.template",
      "application/msword",
    ];
  },
  watch: {
    finalReport() {
      this.setReport(null);
    },
  },
};
</script>

<style></style>
