<template>
  <!-- Button trigger modal -->
  <button
    type="button"
    class="d-none"
    data-bs-toggle="modal"
    id="closeModalButton"
    data-bs-target="#closeResearchModal"
  ></button>

  <!-- Modal -->
  <div
    class="modal fade"
    id="closeResearchModal"
    tabindex="-1"
    aria-labelledby="closeResearchModalLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="closeResearchModalLabel">
            confirmation message
          </h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
            id="closecloseResearchModalBTN"
          ></button>
        </div>
        <div class="modal-body">
          are you sure that you want to close the research ?
        </div>
        <div class="modal-footer">
          <div class="d-flex flex-row justify-content-between w-100">
            <div>
              <p class="text-danger fw-bold float-start">{{ error }}</p>
            </div>
            <div>
              <button
                v-if="!in_submission"
                type="button"
                class="btn btn-primary mx-1"
                @click="closeResearch()"
              >
                yes
              </button>
              <div
                class="spinner-border text-primary"
                role="status"
                v-if="in_submission"
              >
                <span class="visually-hidden">Loading...</span>
              </div>
              <button
                type="button"
                class="btn btn-white mx-1"
                data-bs-dismiss="modal"
              >
                close
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axiosConfig from "@/includes/axiosConfig.js";

export default {
  name: "closeResearch",
  props: {
    researchId: {
      type: Number,
      required: true,
    },
    getResearchInfo: {
      type: Function,
      required: true,
    },
  },
  data() {
    return { in_submission: false, error: null };
  },
  methods: {
    async closeResearch() {
      this.in_submission = true;
      await axiosConfig
        .get(`api/closeResearch/${this.researchId}`)
        .then(() => {
          this.getResearchInfo();
          document.getElementById("closecloseResearchModalBTN").click();
        })
        .catch((err) => {
          this.error = "this action is unAuthorized for now.";
          console.log(err.response);
        });
      this.in_submission = false;
    },
  },
};
</script>

<style></style>
