<template>
  <!-- Modal -->
  <div
    class="modal fade"
    id="replayModal"
    tabindex="-1"
    aria-labelledby="replayModalLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="replayModalLabel">
            Reply to
            {{ comment != null ? comment.sender.full_name : "" }} comment
          </h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body">
          <textarea
            v-model="content"
            class="form-control"
            rows="1"
            placeholder="Type A Comment"
            aria-label="Type A Comment"
          ></textarea>
        </div>
        <div class="modal-footer">
          <button
            type="button"
            class="btn btn-primary"
            :disabled="in_submission"
            @click="addNewComment(content)"
          >
            Send Reply
          </button>
          <button
            type="button"
            class="btn btn-secondary"
            data-bs-dismiss="modal"
          >
            Close
          </button>
        </div>
        <!-- alert message -->
        <div
          v-if="message != null && !in_submission"
          class="alert d-flex align-items-center text-white m-3 text-start"
          :class="messageClass"
          role="alert"
        >
          <div>{{ message }}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axiosConfig from "@/includes/axiosConfig.js";
export default {
  name: "replayModal",
  props: {
    comment: {
      required: true,
    },
    fetchComments: {
      type: Function,
      required: true,
    },
  },
  data() {
    return {
      in_submission: false,
      message: null,
      messageClass: null,
      content: null,
    };
  },
  methods: {
    async addNewComment(content) {
      this.in_submission = true;
      let research = parseInt(this.$route.params.Id);
      let vals = {
        content: content,
      };
      await axiosConfig
        .post(`api/replayComment/${research}/${this.comment.id}`, vals)
        .then((res) => {
          this.messageClass = "alert-success";
          this.message = res.data;
          this.content = null;
          this.fetchComments();
        })
        .catch((err) => {
          console.log(err.response);
          this.messageClass = "alert-danger";
          this.message = "Error, please try again later.";
        });
      setTimeout(() => {
        this.message = null;
      }, 3000);
      this.in_submission = false;
    },
  },
};
</script>

<style></style>
