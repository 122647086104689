<template>
  <!-- Button trigger modal -->
  <button
    type="button"
    class="d-none"
    id="reviewCommentOrReplyButton"
    data-bs-toggle="modal"
    data-bs-target="#reviewCommentOrReplyModal"
  ></button>

  <!-- Modal -->
  <div
    class="modal fade"
    id="reviewCommentOrReplyModal"
    tabindex="-1"
    aria-labelledby="reviewCommentOrReplyModal"
    aria-hidden="true"
  >
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="reviewCommentOrReplyModal">
            Review {{ commentType }} content
          </h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body">
          {{ commentOrReplay.content || "" }}
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-primary" data-bs-dismiss="modal">
            Close
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "reviewCommentOrReplyModal",
  props: {
    commentType: {
      type: String,
      required: true,
    },
    commentOrReplay: {
      type: Object,
      required: true,
    },
  },
};
</script>

<style></style>
