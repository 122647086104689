<template>
  <!-- Button trigger modal -->
  <button
    type="button"
    class="btn btn-primary d-none"
    data-bs-toggle="modal"
    data-bs-target="#modificationInfoModal"
    id="modificationInfoModalBtn"
  ></button>

  <!-- Modal -->
  <div
    class="modal fade"
    id="modificationInfoModal"
    data-bs-backdrop="static"
    data-bs-keyboard="false"
    tabindex="-1"
    aria-labelledby="modificationInfoModalLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-dialog-scrollable modal-lg">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="modificationInfoModalLabel">
            modification details
          </h5>
          <button
            @click="rerenderQuill()"
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body">
          <modification-card
            :modification="modification"
            :showQuilEditor="showQuilEditor"
          />
        </div>
        <div class="modal-footer">
          <button
            @click="rerenderQuill()"
            type="button"
            class="btn btn-primary"
            data-bs-dismiss="modal"
          >
            Close
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import modificationCard from "@/components/modifications/modificationCard.vue";
export default {
  name: "modificationInfoModal",
  components: {
    modificationCard,
  },
  props: {
    modification: {
      required: true,
      type: Object,
    },
    showQuilEditor: {
      reqiured: true,
      type: Boolean,
    },
    rerenderQuill: {
      required: true,
      type: Function,
    },
  },
  data() {
    return {
      oldQuillVal: null,
      newQuillVal: null,
    };
  },
};
</script>

<style></style>
